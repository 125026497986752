
import NavBar from './components/Layout/NavBar';

import Footer from './components/Layout/Footer';
import { BrowserRouter } from "react-router-dom";

import Home from './components/Pages/HomePage/Home';
import { Route, Routes } from "react-router";

import Thank from './components/Pages/ThankPage/Thank';
import WhoAreWe from './components/Pages/WhoAreWePage/WhoAreWePage';
import NotFound from './components/Pages/NotFound/NotFound';
import MentionsLegales from './components/Pages/MentionsLegales/MentionsLegales';
import ContactForm from './components/Pages/Forms/ContactPage/ContactForm';
import ErrorPage from './components/Pages/ErrorPage/ErrorPage';

const App = () => {

  // useEffect(() => {
  //   const TRACKING_ID = "G-76VTJ6WD4Z"
  //   ReactGA.initialize(TRACKING_ID);
  //   ReactGA.pageview(location.pathname + window.location.search);
  // }, [])

  return (
    <section id='Oxton'>
   
 
      <BrowserRouter>
        <NavBar/>
    
       
       <Routes>
    
        <Route path='/' index element={<Home/>} /> 
        <Route path='/fr/contact-digi' index element={<ContactForm/>} />
        <Route path='/fr/thank' index element={<Thank/>} />
        <Route path="/fr/error" element={<ErrorPage/>} />
        <Route path='/fr/qui-sommes-nous' index element={<WhoAreWe/>} />
        <Route path='/fr/mentions-legales' index element={<MentionsLegales/>} />
        <Route path='*' element={<NotFound/>} />
       
      </Routes>
    

      <Footer/>
    
      </BrowserRouter>
   
    </section>
  )
}
export default App 