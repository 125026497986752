import React, { useEffect, useState } from 'react';
import './WhoAreWePage.css'
import Timeline from './Timeline/Timeline';
import BowlingLocations from '../HomePage/BowlingLocations/BowlingLocations';
import PartnerSlider from './PartnerSlider/PartnerSlider';
import Partenaire from '../../../Model/Partenaire';
import { URL_Back } from "../../Pages/Variables";
const WhoAreWe = () => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        fetch(`${URL_Back}/wp-json/wp/v2/partenaires?_embed`)
        .then(res => res.json())
        .then((result) => {
          setItems(result)
        })
    })
    let selectedNames = ['Trivec', 'CashDro', 'Verifone', 'Oxton', 'QubicaAMF', 'BowlingMedia'];
let partenaire = items
  .filter(element => selectedNames.includes(element.title.rendered))
  .map(element => new Partenaire(
    element.id, 
    element.title.rendered, 
    element.excerpt.rendered, 
    element.date, 
    element._embedded['wp:featuredmedia'][0].source_url
  ))
     console.log(partenaire)

    return (
        <div className='container-fluid who-are-we'>
           
            <div className='container'>
            <h1>Qui sommes nous ?</h1>
            <div className='qui-sommes-nous'>
            <p>Grâce à la rencontre d'une équipe d'informaticiens et de plusieurs propriétaires de centres de loisirs multi-activités, le projet DigiServiceForFun a pu voir le jour. Les connaissances techniques d'un coté, et l'expertise métier du monde du loisir indoor de l'autre, a permis la création d'un outil unique en son genre et attendu de tous.
                
            En collaborant avec DigiServiceForFun, vous serez certain de notre écoute, car nous sommes avant tout, confrères et exploitants.</p>
            </div>
            <Timeline />
            <div className='nos-valeurs'>
                <h2>Nos valeurs</h2>

                <div className='valeurs'>
                    <div className='val'>
                        <img src='../images/bienveillance.png' alt='bienveillance' className='val-image' />
                        <p className='val-text'>Bienveillance</p>
                    </div>

                    <div className='val'>
                        <img src='../images/professionalisme.png' alt='professionalisme' className='val-image' />
                        <p className='val-text'>Professionalisme</p>
                    </div>
                    <div className='val'>
                        <img src='../images/creativite.png' alt='creativite' className='val-image' />
                        <p className='val-text'>Créativité</p>
                    </div>
                    <div className='val'>
                        <img src='../images/performance.png' alt='performance' className='val-image' />
                        <p className='val-text'>Performance</p>
                    </div>
                </div>

            </div>
            <div className="rectangle-who mb-4">
                <div className='rec'>
                    <div className='rec1'>2021</div>
                    <div className='rec2'>FONDÉE EN</div>
                </div>
                <div className='rec'>
                    <div className='rec1'>15</div>
                    <div className='rec2'>PERSONNES</div>
                </div>
                <div className='rec'>
                    <div className='rec1'>+ 88M</div>
                    <div className='rec2'>Lignes de code</div>
                </div>
            </div>

         <div>
                <PartnerSlider partners={partenaire} />
             </div> 
            

            <div className='team'>
            {/* <div className="background-image-container"></div> */}
                <h2>Rejoignez notre équipe</h2>
                <p>Vous désirez rejoindre notre équipe ? Vous êtes développeur et souhaitez mettre vos connaissances à profit ? <br/> N’hésitez pas a nous rejoindre !</p>
                <button className="button-who">
                    <div className='d-flex align-items-center justify-content-center gap-3'>
                        
                        <span>Rejoindre notre équipe</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="30" viewBox="0 0 43 22" fill="none">
                            <path d="M42.5073 9.74414C42.5068 9.7436 42.5064 9.74298 42.5058 9.74245L33.7291 0.51664C33.0716 -0.174498 32.0081 -0.171926 31.3536 0.522671C30.6992 1.21718 30.7017 2.34051 31.3592 3.03174L37.2519 9.22581H1.67969C0.751996 9.22581 0 10.0201 0 11C0 11.9799 0.751996 12.7742 1.67969 12.7742H37.2518L31.3593 18.9683C30.7018 19.6595 30.6993 20.7828 31.3537 21.4773C32.0082 22.172 33.0718 22.1744 33.7292 21.4834L42.5059 12.2575C42.5064 12.257 42.5068 12.2564 42.5074 12.2559C43.1653 11.5623 43.1632 10.4354 42.5073 9.74414Z" fill="white" />
                        </svg>
                    </div>
                </button>

            </div>
            </div>
            <div className="locHome">
            <BowlingLocations/>
            </div>

        </div>

    );
}

export default WhoAreWe;
